.chat-messages-scroller {
  flex-grow: 1;
  overflow-y: scroll;
  overscroll-behavior: contain;
  display: flex;
  flex-direction: column-reverse;
  z-index: 1;
  margin: 0 1px 0 0;
  will-change: transform;
  @include chat-scrollbar;
  min-height: 1px;
  box-sizing: border-box;
  transition: padding-top 0.2s ease-in-out;
}
