.chat-modal-move-message-to-channel {
  &__channel-chooser {
    width: 100%;

    .category-chat-badge {
      .d-icon {
        color: inherit;
      }
    }
  }
}
