.chat-composer-uploads {
  max-width: 100%;

  .chat-composer-uploads-container {
    padding: 0.5rem 1rem;
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
  }
}
