.chat-form {
  display: flex;
  flex-direction: column;
}

.chat-form__row {
  &.-separator {
    border-bottom: 1px solid var(--primary-low);
  }
}

.chat-form__section {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;

  &-title {
    font-weight: 700;
    font-size: var(--font-down-1);
    color: var(--primary-medium);
  }

  &-title + &-content {
    margin-top: 0.25rem;
  }

  &-content {
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }
}

.chat-form__row {
  display: flex;
  width: 100%;

  // background: green;
  flex-direction: column;
  justify-content: center;

  label,
  .d-toggle-switch__checkbox-slider {
    margin: 0;
  }

  &-action {
    .chat-form__btn:first-child {
      padding-left: 0;
    }
  }

  &-label + &-action {
    margin-left: auto;
  }

  &.-link {
    color: var(--primary);

    .d-icon {
      color: var(--primary-medium);
    }
  }

  &-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.25rem;
  }

  &-description {
    display: flex;
    padding-top: 3px;
    color: var(--primary-medium);
    font-size: var(--font-down-1);
  }
}
