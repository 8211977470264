.chat-transcript {
  @extend .chat-message-container;
  box-sizing: border-box;
  min-height: 50px;
  padding: 12px;
  margin: 1rem 0;

  @include post-aside;

  .chat-messages-container & {
    display: block;
  }

  &.chat-transcript-chained {
    margin: 0;
    border-top: 0;
    border-bottom: 0;
  }

  details > .chat-transcript-chained:first-of-type {
    margin-top: 0.5rem;
  }

  .chat-transcript-meta {
    color: var(--primary-high);
    font-size: var(--font-down-2-rem);
    border-bottom: 1px solid var(--primary-low);
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;

    img.emoji {
      height: 1.1em;
      width: 1.1em;
    }
  }

  .chat-transcript-separator {
    font-size: var(--font-down-3-rem);
    color: var(--primary-high);
    padding: 0 0.5rem;
  }

  .chat-transcript-username {
    color: var(--primary-high-or-secondary-low);
    font-weight: bold;
  }

  .chat-transcript-datetime {
    color: var(--primary-high);
    font-size: var(--font-down-2-rem);
    padding: 0 0.5rem;

    a {
      color: var(--primary-high);
    }
  }

  .chat-transcript-messages {
    p {
      margin: 0.5rem 0;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  .topic-body .cooked & {
    > details {
      padding: 0.5rem;

      > summary {
        display: flex;

        &::before {
          line-height: 1;
        }

        .chat-transcript-thread {
          min-width: 0;

          &-header {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }

  .chat-transcript-user-avatar .avatar {
    aspect-ratio: 20 / 20;
  }

  .chat-transcript-user {
    display: flex;
    flex-wrap: wrap-reverse;
    gap: 0.25rem 0;
    align-items: baseline;

    .chat-transcript-user-avatar {
      padding-right: 0.5rem;
    }
  }

  .chat-transcript-reactions {
    margin-top: 0.5em;

    .chat-transcript-reaction {
      @include chat-reaction;
      pointer-events: none;
    }
  }

  .chat-transcript-images.onebox {
    display: flex;
    flex-wrap: wrap;

    img {
      width: auto;
      max-height: 150px;
      margin-right: 0.5rem;
      margin-top: 0.5em;
    }
  }

  pre code {
    box-sizing: border-box;
  }
}
