.chat-composer-button {
  @include breakpoint(mobile-large) {
    width: 50px;
  }
  box-sizing: border-box;
  border: 0;
  height: 50px;
  background: none;
  padding-inline: 0.75rem;

  .is-disabled & {
    cursor: not-allowed;
  }

  &__wrapper {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
  }

  .d-icon {
    color: var(--primary-medium);

    &:hover {
      color: var(--primary-medium);
    }

    .is-focused & {
      color: var(--primary-high);
    }

    .is-disabled & {
      color: var(--primary-low-mid);
      cursor: not-allowed;
    }
  }

  &.-send {
    will-change: scale;

    @keyframes sendingScales {
      0% {
        transform: scale(0.9);
      }

      50% {
        transform: scale(1.2);
      }

      100% {
        transform: scale(0.9);
      }
    }

    .is-send-disabled & {
      cursor: default;
    }

    .chat-composer.is-sending & {
      @media (prefers-reduced-motion: no-preference) {
        animation: sendingScales 1s infinite linear;
      }
    }

    .d-icon {
      .is-send-enabled.is-focused & {
        color: var(--tertiary);
      }
    }

    &:hover {
      .d-icon {
        .is-send-enabled & {
          transform: scale(1.2);
        }
      }
    }
  }

  &.-emoji {
    transition: transform 0.1s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }

    .d-icon {
      .is-focused & {
        color: var(--tertiary);
      }
    }
  }
}
