.c-channel-members {
  max-width: 500px;
  width: 100%;

  &__filter {
    margin-bottom: 1rem;
  }

  &__list {
    display: flex;
    margin: 0;
    flex-direction: column;

    &-item {
      display: flex;
      list-style: none;
      border-bottom: 1px solid var(--primary-low);
      align-items: center;
      box-sizing: border-box;
      padding: 0.5rem;
      cursor: pointer;

      .avatar {
        cursor: pointer;
      }

      &:hover {
        background: var(--primary-very-low);
      }

      &.-member {
        .chat-user-avatar {
          margin-right: 0.5rem;
        }

        .chat-user-display-name {
          @include ellipsis;

          span:not(.-first) {
            color: var(--primary-high);
            font-size: var(--font-down-1);
            margin-left: 0.5rem;
          }
        }

        .fk-d-tooltip__trigger-container {
          gap: 0.5rem;
        }

        .user-status-message {
          color: var(--primary-medium);
          font-size: var(--font-down-2);
          margin-left: 0.5rem;

          .emoji {
            height: var(--font-0);
            width: var(--font-0);
          }
        }
      }

      &.-add-member {
        color: var(--tertiary);
        cursor: pointer;

        .d-icon {
          background: var(--primary-low);
          color: var(--tertiary);
          padding: 5px;
          border-radius: 50%;
          margin-right: 0.5rem;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
