.chat-selection-management {
  border-top: 1px solid var(--primary-low);
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;

  .chat-drawer-content & {
    flex-direction: column;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    .chat-drawer-content & {
      flex-direction: column;
      width: 100%;
    }
  }

  &__copy-success {
    animation: chat-quote-message-background-fade-highlight 2s ease-out 3s;
    @media (prefers-reduced-motion) {
      animation-duration: 0s;
    }
    animation-fill-mode: forwards;
    background-color: var(--success-low);
    color: var(--primary);
    flex: 1;
    line-height: normal;
    padding: 0.5rem 0.65rem;
  }

  @keyframes chat-quote-message-background-fade-highlight {
    0% {
    }

    100% {
      background-color: transparent;
      color: transparent;
    }
  }
}
