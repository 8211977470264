.chat-channel-preview-card {
  margin: 1rem 0 2rem 0;
  padding: 1.5rem 1rem;
  background-color: var(--secondary-very-high);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;

  &.-no-description {
    .chat-channel-title {
      margin-bottom: 1.5rem;
    }
  }

  &.-no-button {
    .chat-channel-preview-card__browse-all {
      margin-top: 0;
    }
  }

  &__description {
    color: var(--primary-600);
    text-align: center;
    overflow-wrap: break-word;
    max-width: 100%;
  }

  .chat-channel-title__name {
    font-size: var(--font-up-2);
  }

  &__join-channel-btn {
    font-size: var(--font-up-2);
    border: 1px solid transparent;
    border-radius: var(--d-button-border-radius);
    line-height: normal;
    box-sizing: border-box;
    padding: 0.5em 0.65em;
    font-weight: normal;
    cursor: pointer;
  }

  &__browse-all {
    margin-top: 1rem;
    font-size: var(--font-down-1);
  }
}
