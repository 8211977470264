.chat-modal-edit-channel-description {
  .exceeded-word-count {
    .chat-modal-edit-channel-description__description-input {
      outline: 1px solid var(--danger);
      border: 1px solid var(--danger);
    }
  }

  &__description-input {
    display: flex;
    margin: 0;
    min-height: 200px;
  }

  &__description {
    display: flex;
    padding-bottom: 0.75rem;
    color: var(--primary-medium);
  }
}
