.c-user-thread {
  padding-block: 1.25rem;
  margin-inline: 1rem;
  border-bottom: 1px solid var(--primary-low);

  .c-unread-indicator {
    width: 8px;
    height: 8px;
    align-self: flex-start;
  }

  .chat-message-thread-indicator {
    margin-left: 0;
    margin-top: 1rem;

    &:hover {
      box-shadow: none;
    }
  }

  .chat__thread-title {
    &-container {
      display: flex;
      gap: 1rem;
    }

    &__name {
      font-size: var(--font-up-1-rem);
      word-break: break-all;

      &:hover {
        color: var(--primary-very-high);
      }
    }
  }

  .chat-channel-icon {
    font-size: var(--font-down-1-rem);
  }

  .chat-channel-name {
    font-size: var(--font-down-1-rem);
    color: var(--primary-high);
  }

  .chat-channel-title__name {
    color: var(--primary-high);
    font-size: var(--font-down-1);
  }
}

.c-user-threads .empty-state-threads {
  display: flex;
  justify-content: center;
  padding: 1.25rem 1rem;
  font-size: var(--font-up-1-rem);
  color: var(--primary);
}

//sidebar
#sidebar-section-content-user-threads {
  padding-bottom: 0.35em;

  .sidebar-section-link-content-text {
    color: var(--d-sidebar-link-color);
  }

  .active .sidebar-section-link-content-text {
    color: var(--d-sidebar-active-color);
  }
}
