.chat-modal-channel-summary {
  .summarization-since,
  .summary-area {
    margin: 10px 0 10px 0;
  }

  .summary-area {
    min-height: 50px;
  }
}
