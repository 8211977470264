@mixin chat-scrollbar() {
  --scrollbarBg: transparent;
  --scrollbarThumbBg: var(--primary-low);
  --scrollbarWidth: 10px;
  scrollbar-color: transparent var(--scrollbarBg);
  transition: scrollbar-color 0.25s ease-in-out;

  // chrome, safari
  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbarThumbBg);
    border-radius: calc(var(--scrollbarWidth) / 2);
    border: calc(var(--scrollbarWidth) / 4) solid var(--secondary);
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: var(--scrollbarWidth);
  }

  // firefox
  & {
    scrollbar-color: var(--scrollbarThumbBg) var(--scrollbarBg);
    scrollbar-width: thin;
  }

  &::-moz-scrollbar-thumb {
    background-color: var(--scrollbarThumbBg);
    border-radius: calc(var(--scrollbarWidth) / 2);
    border: calc(var(--scrollbarWidth) / 4) solid var(--secondary);
  }

  &::-moz-scrollbar-track {
    background-color: transparent;
  }

  &::-moz-scrollbar {
    width: var(--scrollbarWidth);
  }
}

.chat-message-text {
  pre {
    scrollbar-color: var(--primary-300) transparent;
  }
}
