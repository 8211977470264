.has-full-page-chat {
  .global-notice,
  .bootstrap-mode-notice {
    display: none;
  }
}

.admin-plugins {
  [data-plugin-name="chat"] {
    display: none;
  }
}

.user-summary-page .details .controls ul,
.user-main .details .controls ul {
  flex-direction: column;

  li:first-child {
    order: -2;
  }

  li.user-profile-controls-outlet.chat-button {
    order: -1;

    &:empty {
      display: none;
    }
  }
}

.user-main .collapsed-info .details .controls ul {
  flex-direction: row;
}
