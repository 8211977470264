.chat-channel {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 1px;
  position: relative;
  overflow: hidden;
  grid-area: main;
  min-width: 250px;
  @include chat-height(var(--chat-header-offset, 0));

  .join-channel-btn.in-float {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 10px;
    z-index: 10;
  }

  .all-loaded-message {
    text-align: center;
    color: var(--primary-medium);
    font-size: var(--font-down-1);
    padding: 0.5em 0.25em 0.25em;
    touch-action: none;
    height: 100%;
  }
}
