body.composer-open .chat-drawer-outlet-container {
  bottom: 11px; // prevent height of grippie from obscuring ...is typing indicator
}

.chat-drawer-resizer {
  position: absolute;
  top: -5px;
  width: 15px;
  height: 15px;
  z-index: z("composer", "content");

  .is-collapsed & {
    display: none;
  }
}

html:not(.rtl) {
  .chat-drawer-resizer {
    cursor: nwse-resize;
    left: -5px;
  }
}

html.rtl {
  .chat-drawer-resizer {
    cursor: nesw-resize;
    right: -5px;
  }
}

.chat-drawer-outlet-container {
  // higher than timeline, lower than composer, dropdown, and user card
  z-index: z("chat-drawer");
  position: fixed;
  right: var(--composer-right, 20px);
  left: 0;
  max-height: calc(100% - var(--header-offset) - 15px);
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  pointer-events: none !important;
  bottom: 0;
  box-sizing: border-box;
  padding-bottom: var(--composer-height, 0);
  transition: all 100ms ease-in;
  transition-property: bottom, padding-bottom;

  .rtl & {
    left: var(--composer-right, 20px);
    right: 0;
  }
}

.chat-drawer {
  pointer-events: auto;
  align-self: flex-end;
  width: 400px;
  min-width: 250px !important; // important to override inline styles
  max-width: calc(100% - var(--composer-right));
  min-height: 300px !important; // important to override inline styles

  .chat-drawer-container {
    background: var(--secondary);
    border: 1px solid var(--primary-low);
    border-bottom: 0;
    border-top-left-radius: var(--d-border-radius-large);
    border-top-right-radius: var(--d-border-radius-large);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.125);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }

  &.is-expanded {
    .chat-drawer-container {
      height: 100%;
    }
  }

  &.is-collapsed {
    min-height: 0 !important;
    height: auto !important;
    max-width: 25vw;
  }

  .chat-channel,
  .chat-thread,
  .chat-thread-list {
    height: 100%;
  }
}

.chat-drawer-content {
  @include chat-scrollbar;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  min-height: 1px;
  position: relative;
  overflow-y: auto;
  overscroll-behavior: contain;

  .c-channel-settings {
    flex-grow: 1;

    .chat-form {
      height: 100%;
    }

    .chat-form__section.--leave-channel {
      margin-top: auto;
      margin-bottom: 0;
    }
  }
}
