.btn-floating.open-new-message-btn {
  position: fixed;
  background: var(--tertiary);
  bottom: 5rem;
  right: 2rem;
  border-radius: 50%;
  font-size: var(--font-up-3);
  padding: 0.5rem;
  transition: transform 0.25s ease, box-shadow 0.25s ease;
  z-index: z("usercard");
  box-shadow: 0 5px 5px -1px rgba(0, 0, 0, 0.25);

  .d-icon {
    color: var(--primary-very-low);
  }

  &:active {
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.25);
    transform: scale(0.9);
  }

  &:focus {
    @include default-focus;
    border-color: var(--d-nav-color--active);
    outline-color: var(--d-nav-color--active);
  }
}

.channels-list,
.channels-list-container {
  position: relative;
  @include chat-scrollbar;
  height: 100%;
  padding-bottom: 1rem;

  @include breakpoint(mobile-large) {
    @include chat-scrollbar;
  }

  .open-browse-page-btn,
  .open-draft-channel-page-btn,
  .chat-channel-leave-btn {
    position: relative;
    padding: 0;
    background: transparent;
    color: var(--primary-medium);
    font-size: var(--font-0-rem);

    &::after {
      content: "";
      position: absolute;
      top: -50%;
      left: -50%;
      width: 200%;
      height: 200%;
    }

    &:hover {
      background: transparent;

      .d-icon {
        color: var(--primary);
      }
    }
  }

  .channel-list-empty-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    height: 100%;

    .channel-title {
      margin-bottom: 1rem;
    }
  }
}

.chat-channel-divider {
  padding: 0.5rem 1rem 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--heading-font-family);
  color: var(--primary);

  .channel-title {
    line-height: var(--line-height-medium);
  }
}
