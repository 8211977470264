.chat-thread-list-header {
  border-bottom: 1px solid var(--primary-low);
  border-top: 1px solid var(--primary-low);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-inline: 0.5rem;

  &__buttons {
    display: flex;
    margin-left: auto;
  }

  &__label span {
    font-weight: bold;
  }

  &__label-channel {
    display: block;
    font-size: var(--font-down-1-rem);
  }
}
