.chat-composer {
  &__wrapper {
    display: flex;
    flex-direction: column;
    z-index: 3;
    padding: 0.5rem 0 env(safe-area-inset-bottom) 0;

    .keyboard-visible & {
      padding-bottom: 0;
    }

    #chat-full-page-uploader,
    #chat-widget-uploader {
      display: none;
    }

    .drop-a-file {
      display: none;
    }

    .chat-replying-indicator {
      padding-inline: 1rem;
    }
  }

  .chat-composer-button,
  .chat-composer-separator {
    align-self: flex-end;
  }

  .chat-composer-separator {
    flex-shrink: 0;
  }

  &__outer-container {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding-inline: 1rem;
  }

  &__inner-container {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    flex-direction: row;
    border: 1px solid var(--primary-low);
    border-radius: var(--d-border-radius-large);
    background: rgba(var(--primary-very-low-rgb), 0.5);
    min-height: 50px;
    overflow: hidden;

    .chat-composer.is-focused & {
      box-shadow: 0 0 1px 0 var(--tertiary);
    }

    .chat-composer.is-disabled & {
      background: var(--primary-low);

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &__input-container {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    align-self: stretch;
  }

  --100dvh: 100vh;
  @supports (height: 100dvh) {
    --100dvh: 100dvh;
  }

  &__input {
    overflow-x: hidden;
    width: 100%;
    appearance: none;
    outline: none;
    border: 0;
    resize: none;
    max-height: calc(var(--100dvh) / 4);
    background: none;
    padding: 0;
    margin: 5px 0;
    text-overflow: ellipsis;
    cursor: inherit;
    @include chat-scrollbar;
    white-space: pre-wrap !important;
    min-width: 20px;

    &[disabled] {
      background: none;

      .d-icon {
        opacity: 0.4;
      }
    }

    &:focus,
    &:active {
      outline: none;
    }

    &:placeholder-shown,
    &::placeholder {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__unreliable-network {
    color: var(--danger);
    padding: 0 0.5em;
  }
}

.chat-composer-message-details {
  margin: 0 1rem 0.5rem 1rem;
  display: flex;
  align-items: center;

  .cancel-message-action {
    margin-left: auto;
  }
}
