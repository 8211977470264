@mixin thread-list-item {
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
  margin-inline: 1rem 0.75rem;
  border-bottom: 1px solid var(--primary-low);
  cursor: pointer;
}

.chat-thread-list-item {
  @include thread-list-item;

  & + .chat-thread-list-item {
    margin-top: -1px;
  }

  .touch & {
    &:active {
      background-color: var(--d-hover);
      padding-inline: 1rem 0.75rem;
      margin-inline: 0;
    }
  }

  .no-touch & {
    &:hover,
    &:active {
      background-color: var(--d-hover);
      padding-inline: 1rem 0.75rem;
      margin-inline: 0;
    }
  }

  &__members {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &__participants {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-right: 0.25rem;
  }

  &__main {
    flex: 1 1 100%;
    width: 100%;
  }

  &__body {
    padding-bottom: 0.25rem;
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    > * {
      pointer-events: none;
    }
  }

  &__last-reply-author {
    font-weight: 700;
    font-size: var(--font-down-1);
    color: var(--primary-high);
  }

  &__metadata {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .chat-user-avatar {
      cursor: pointer;

      &__container {
        padding: 0;
      }

      .avatar {
        border: 2px solid var(--primary-very-low);
        padding: 0;

        .chat-thread-list-item:hover & {
          border-color: var(--d-hover);
        }
      }

      &:not(:last-child) {
        margin-right: -6px;
      }
    }

    .chat-thread-participants__avatar-group {
      overflow: hidden;
      justify-content: flex-start;
    }
  }

  &__last-reply-timestamp {
    flex-shrink: 0;
    color: var(--primary-medium);
    font-size: var(--font-down-2);
    @include ellipsis;
    margin-left: auto;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  &__title {
    flex: 1 1 auto;
    @include ellipsis;
  }

  &__unread-indicator {
    flex: 0 0 auto;
  }

  &__open-button {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
  }

  &__om-user-avatar {
    margin-right: 0.5rem;
    flex: 0 0 auto;
  }
}
