.c-navbar-container {
  position: relative;
  border-bottom: 1px solid var(--primary-low);
  background: var(--secondary);
  box-sizing: border-box;
  display: flex;
  padding: 0 0.67em;

  &.-clickable {
    cursor: pointer;
  }
}

.c-navbar {
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 0.25rem;
  position: relative;
  container-type: inline-size;

  .is-collapsed & {
    &__title {
      margin-left: 0 !important;
    }

    &__toggle-drawer-button {
      &::after {
        @container (inline-size) {
          content: "";
          position: absolute;
          height: 100%;
          left: -1rem;
          width: calc(100cqw - 2.3em + 1rem);
        }

        &:hover {
          color: var(--tertiary-hover);

          .d-icon {
            color: inherit;
          }
        }
      }
    }
  }

  .single-select-header {
    padding: 0.3675rem 0.584rem;
  }
}

.c-navbar__back-button {
  height: var(--chat-header-offset);
  padding: 0.5rem 0.8rem 0.5rem 0.25rem;
}

.c-navbar__channel-title {
  @include ellipsis;
  font-weight: 700;
  height: var(--chat-header-offset);
  display: flex;
  align-items: center;
}

.c-navbar__title {
  display: flex;
  align-items: center;
  @include ellipsis;
  height: var(--chat-header-offset);

  &-text {
    @include ellipsis;
    font-weight: 700;
    max-width: 100%;
    vertical-align: middle;
    padding-block: 5px;

    > .d-icon {
      margin-right: 5px;
    }
  }

  .d-icon {
    vertical-align: middle;
  }
}

.c-navbar__sub-title {
  line-height: var(--line-height-small);
  font-size: var(--font-down-1-rem);
  font-weight: normal;
}

.c-navbar__threads-list-button {
  gap: 0.25rem;

  &.has-unreads {
    .d-icon-discourse-threads {
      color: var(--tertiary-med-or-tertiary);
    }
  }
}

.c-navbar__actions {
  list-style: none;
  margin-left: auto;
  display: flex;
  align-items: center;
  height: var(--chat-header-offset);

  .d-icon {
    color: var(--primary-low-mid);
  }
}

.c-navbar__back-button ~ .c-navbar__title {
  padding-left: 0;
}
