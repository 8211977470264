.c-footer {
  grid-area: footer;
  background: var(--secondary);
  border-top: 1px solid var(--primary-low);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  left: 0;
  padding-bottom: env(safe-area-inset-bottom);

  html.footer-nav-ipad &,
  html.footer-nav-visible & {
    padding-bottom: calc(
      env(safe-area-inset-bottom) + var(--footer-nav-height, 0px)
    );
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 33%;
    flex-shrink: 0;
    flex-grow: 1;
    padding-block: 0.75rem;
    height: 100%;
    position: relative;
    color: var(--primary-medium);

    &.--active {
      .d-icon,
      .d-button-label {
        color: var(--d-nav-color--active);
      }
    }

    &:hover,
    &:focus {
      .discourse-no-touch &,
      .discourse-touch & {
        .d-icon,
        .d-button-label {
          color: var(--d-nav-color--active);
        }
      }
    }

    .d-icon {
      margin-right: 0;
      font-size: var(--font-up-2);
      color: inherit;

      &.d-icon-discourse-threads {
        font-size: var(--font-up-1); //visual correction
      }
    }

    .d-button-label {
      font-size: var(--font-down-1-rem);
      color: var(--primary-medium);
    }

    .c-unread-indicator {
      position: absolute;
      top: 0.35rem;
      right: unset;
      left: 50%;
      margin-left: 0.75rem;

      &.-urgent {
        height: 1em;
        width: min-content;
        min-width: 0.6em;
        padding: 0.21em 0.42em;
      }

      &:not(.-urgent) {
        width: 11px;
        height: 11px;
      }
    }
  }
}
